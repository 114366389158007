@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* purgecss start ignore */
/* @tailwind base; */
/* purgecss end ignore */
.m-0{
  margin: 0px;
}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}
.my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mx-4{
  margin-left: 1rem;
  margin-right: 1rem;
}
.mb-4{
  margin-bottom: 1rem;
}
.mt-10{
  margin-top: 2.5rem;
}
.mb-10{
  margin-bottom: 2.5rem;
}
.mt-0{
  margin-top: 0px;
}
.mt-20{
  margin-top: 5rem;
}
.mr-4{
  margin-right: 1rem;
}
.mt-4{
  margin-top: 1rem;
}
.mb-0{
  margin-bottom: 0px;
}
.mt-2{
  margin-top: 0.5rem;
}
.mt-8{
  margin-top: 2rem;
}
.mb-2{
  margin-bottom: 0.5rem;
}
.ml-4{
  margin-left: 1rem;
}
.ml-2{
  margin-left: 0.5rem;
}
.flex{
  display: flex;
}
.hidden{
  display: none;
}
.min-h-screen{
  min-height: 100vh;
}
.max-w-md{
  max-width: 28rem;
}
.max-w-3xl{
  max-width: 48rem;
}
.max-w-2xl{
  max-width: 42rem;
}
.list-none{
  list-style-type: none;
}
.flex-col{
  flex-direction: column;
}
.items-center{
  align-items: center;
}
.justify-center{
  justify-content: center;
}
.justify-between{
  justify-content: space-between;
}
.space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}
.self-center{
  align-self: center;
}
.justify-self-end{
  justify-self: end;
}
.rounded-full{
  border-radius: 9999px;
}
.p-0{
  padding: 0px;
}
.p-4{
  padding: 1rem;
}
.p-2{
  padding: 0.5rem;
}
.px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-8{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.text-center{
  text-align: center;
}
.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-3xl{
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.font-bold{
  font-weight: 700;
}
.italic{
  font-style: italic;
}
.leading-none{
  line-height: 1;
}
.no-underline{
  text-decoration: none;
}
.opacity-75{
  opacity: 0.75;
}
.justify-self-end{
  justify-self: flex-end;
}

:root {
  /* Default Dark Theme */
  --primary: hsla(292, 100%, 50%, 1);
  --textColor: white;
  --backgroundColor: #0d1219;
  --alternateBackgroundColor: #edf2f7;
  --blockquoteColor: rgba(255, 255, 255, 0.2);
  --icon: white;
}

html {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
}

body {
  color: var(--textColor);
  background-color: var(--backgroundColor);
  margin: 0;
  overflow-x: hidden;
}

body.light {
  /* Light Theme */
  --primary: hsla(292, 100%, 50%, 1);
  --textColor: black;
  --backgroundColor: #edf2f7;
  --alternateBackgroundColor: #0d1219;
  --blockquoteColor: rgba(0, 0, 0, 0.8);
  --icon: #0d1219;
}

a {
  color: inherit;
}

.layout {
  background-color: var(--backgroundColor);
}

code {
  font-size: 0.85rem;
}

@media (max-width: 767px) {
  pre {
    margin: 0 -1.5rem !important;
    border-radius: 0 !important;
  }
}

.post-container h1,
.post-container h2,
.post-container h3,
.post-container p {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.post-container hr {
  max-width: 128px;
  opacity: 0.75;
  margin: 48px auto;
}

.blog-title {
  color: var(--blogTitle);
}

.container {
  max-width: 95%;
  width: 980px;
}

.blog-title,
.date-and-duration {
  color: var(--primary);
}

.text-primary {
  color: var(--primary);
}

.bg-secondary {
  background-color: var(--backgroundColor);
}

.theme-switch {
  width: 44px;
  height: 22px;
  position: relative;
  display: inline-block;
}

.theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.theme-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  transition: 0.3s;
  border-radius: 11px;
  border: 2px solid var(--alternateBackgroundColor);
  border: 2px solid;
}

.theme-switch .slider::before {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  bottom: 50%;
  left: -2px;
  background-color: var(--backgroundColor);
  transition: 0.3s;
  border-radius: 50%;
  transform: translate(0, 50%);
  border: 2px solid var(--alternateBackgroundColor);
  z-index: 2;
}

.theme-switch input:checked + .slider::before {
  transform: translate(23px, 50%);
}

.toggle-image {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  transform: translateY(1px);
}

.toggle-image.dark {
  left: 0.2rem;
}

.toggle-image.light {
  right: 0.12rem;
}

@media (min-width: 768px){
  .md\:mt-28{
    margin-top: 7rem;
  }
  .md\:mb-8{
    margin-bottom: 2rem;
  }
}

